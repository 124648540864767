import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    * {
        font-family: 'Plus Jakarta Sans', sans-serif;
        margin: 0;
        padding: 0;
        letter-spacing: -.01em;
    }

    *, *::before, *::after {
        box-sizing: border-box;
    }

    h1, h2, h3, h4 {
        margin: 0;
        padding: 0;
    }

    body {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    html {
        scroll-behavior: smooth 
    }
`;

export const Section = styled.div`
    background-color: ${({ backgroundColor }) => backgroundColor};
    position: relative;
    padding: 3rem 0;
    position: relative;
    z-index: 0;

    @media screen and (min-width: 1024px) {
        padding: 6rem 0;
    }
`;

export const SectionWrapper = styled.div`
    margin: 0 auto;
    max-width: 1180px;
    position: relative;
    z-index: 2;
`

export const SectionHeader = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 1rem;

    & > h1 {
        color: #146EF5;
        font-size: 14px;
    }

    & > h2 {
        font-size: 47px;
        color: #333333;
        letter-spacing: -2px;
        text-align: center;
    }

    & > p {
        color: #333;
        opacity: .6;
        font-size: 20px;
        line-height: 1.8;
        margin-top: 1rem;
    }

    @media screen and (min-width: 1024px) {
        padding: 0;
        & > p {
            max-width: 50%;
        }
    }
`;

export const SectionHeaderCentered = styled(SectionHeader)`
    justify-content: center;
    align-items: center;

    & > p {
        text-align: center;
    }
`;