import styled from "styled-components";

export const StructureGrid = styled.div`
    display: grid;
    margin-top: 2rem;
    padding: 0 1rem;

    @media screen and (min-width: 1024px) {
        padding: 0;
    }
`;

export const StructureCard = styled.div`
    border-top: 1px solid #ddd;
    padding: 2rem 0;
`;

export const StructContent = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    & > h1 {
        flex: 1;
        font-size: 24px;
        margin: 1rem 0 8px;
    }

    & > p {
        flex: 1;
        color: #333;
        opacity: .7;
        font-size: 20px;
        line-height: 1.5;
    }

    @media screen and (min-width: 1024px) {
        flex-direction: row;
        align-items: center;

        & > h1 {
            font-size: 28px;
            margin: 0 0 0 4rem;
        }
    }
`;

export const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    border-radius: 10px;
    background-color: #146EF5;
    color: #fff;
    align-self: flex-start;
`;