import React from "react";
import img01 from '../../../assets/images/fundraising/01.png';
import img02 from '../../../assets/images/fundraising/02.png';
import img03 from '../../../assets/images/fundraising/03.png';
import { Section, SectionHeaderCentered, SectionWrapper } from "../../../global";
import { BlackOverlay, FundCard, FundGrid, FundTitle } from "./style";

const data = [
    {
        title: "Exclusive Access",
        desc: "Connections to top PE/VC and angel networks across South East Asia",
        image: img03
    },
    {
        title: "Strategic Investment",
        desc: "Strategy development to attract investors",
        image: img02
    },
    {
        title: "Active Networking",
        desc: "Regularly connecting with potential capital access",
        image: img01
    }
]

export const Fundraising = () => {
    return (
        <Section backgroundColor="#FAFAFC">
            <SectionWrapper>
                <SectionHeaderCentered data-aos="fade-down">
                    <h1>FEATURED SERVICES</h1>
                    <h2>Fundraising</h2>
                    <p>Tap Financier Through Our Network</p>
                </SectionHeaderCentered>
                <FundGrid>
                    {data.map((item, index) => <FundCard key={index} data-aos="fade-up">
                        <img src={item.image} alt={item.title} />
                        <BlackOverlay />
                        <FundTitle>
                            <h1>{item.title}</h1>
                            <p>{item.desc}</p>
                        </FundTitle>
                    </FundCard>)}
                </FundGrid>
            </SectionWrapper>
        </Section>
    )
}