import { FlowArrow, GearFine, SelectionAll, ShuffleAngular, Swap } from "@phosphor-icons/react";
import React from "react";
import { Section, SectionHeaderCentered, SectionWrapper } from "../../../global";
import { IconWrapper, StructContent, StructureCard, StructureGrid } from "./style";

const data = [
    {
        icon: <ShuffleAngular size={30} />,
        title: "Transition",
        desc: "From family to professional and generation shift."
    },
    {
        icon: <GearFine size={30} />,
        title: "Efficiency Optimization",
        desc: "Streamlining operations for maximum efficiency."
    },
    {
        icon: <SelectionAll size={30} />,
        title: "Scalable Systems",
        desc: "Implementing system for rapid, sustainable growth, leveraging digital transformation."
    },
    {
        icon: <FlowArrow size={30} />,
        title: "Exit Readiness",
        desc: "Preparing your business for successful IPO or exit"
    },
    {
        icon: <Swap size={30} />,
        title: "Post Exit Maintenance",
        desc: "Ensure your company to fulfill obligation and requirement post exit program."
    },
]

export const Restructure = () => {
    return (
        <Section backgroundColor="#FAFAFC">
            <SectionWrapper>
                <SectionHeaderCentered data-aos="fade-down">
                    <h1>FEATURED SERVICES</h1>
                    <h2>Operational Restructure</h2>
                    <p>We guide you to operate effectively and efficiently.</p>
                </SectionHeaderCentered>
                <StructureGrid>
                    {data.map((item, index) => <StructureCard data-aos="fade-up" key={index} style={{ borderBottom: index === data.length - 1 ? "1px solid #ddd" : "0" }}>
                        <StructContent>
                            <IconWrapper>
                                {item.icon}
                            </IconWrapper>
                            <h1>{item.title}</h1>
                            <p>{item.desc}</p>
                        </StructContent>
                    </StructureCard>)}
                </StructureGrid>
            </SectionWrapper>
        </Section>
    )
}