import { NotePencil, UserFocus, UsersFour } from "@phosphor-icons/react";
import React from "react";
import { Section, SectionWrapper } from "../../../global";
import { ProHiringCard, ProHiringGrid, SectionFlex, SectionRight } from "./style";

export const ProHiring = () => {
    return (
        <Section>
            <SectionWrapper style={{ maxWidth: "940px" }}>
                <SectionFlex data-aos="fade-down">
                    <SectionRight>
                        <h1>FEATURED SERVICES</h1>
                        <h2>Pro-Hiring</h2>
                    </SectionRight>
                    <p>Fill in critical roles with talent that is proven through our past experience working and collaborating with them</p>
                </SectionFlex>
                <ProHiringGrid>
                    <ProHiringCard data-aos="fade-up">
                        <NotePencil size={40} />
                        <h1>Critical Role Assessment</h1>
                        <p>Identifying essential roles and requirements</p>
                    </ProHiringCard>
                    <ProHiringCard data-aos="fade-up">
                        <UserFocus size={40} />
                        <h1>Targeted <br /> Requirement</h1>
                        <p>Finding candidates who match your needs</p>
                    </ProHiringCard>
                    <ProHiringCard data-aos="fade-up">
                        <UsersFour size={40} />
                        <h1>Cultural <br />Fit Focus</h1>
                        <p>Ensuring candidates align with your company culture</p>
                    </ProHiringCard>
                </ProHiringGrid>
            </SectionWrapper>
        </Section>
    )
}