import styled from "styled-components";

export const WhyGrid = styled.div`
    display: grid;
    margin-top: 2rem;
    gap: 1rem;
    padding: 0 1rem;

    @media screen and (min-width: 1024px) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
        gap: 2rem;
        justify-content: center;
        padding: 0;
    }
`;

export const WhyCard = styled.div`
    background-color: #FAFAFC;
    border-radius: 10px;
    padding: 2rem;
`;

export const WhyContent = styled.div`
    display: flex;
    flex-direction: column;

    & > h1 {
        font-size: 21px;
        margin: 1rem 0 8px;
    }

    & > p {
        font-size: 17px;
        color: #1E1E24;
        opacity: .7;
        line-height: 1.5;
    }
`;

export const ImgWrapper = styled.div`
    display: inline-flex;

    & > img {
        max-width: 100%;
        height: 56px;
        aspect-ratio: auto 35 / 56;
    }

`;