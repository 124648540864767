import styled from "styled-components";

export const ServiceGrid = styled.div`
    display: grid;
    margin-top: 2rem;
    gap: 1rem;
    padding: 0 1rem;

    @media screen and (min-width: 1024px) {
        grid-template-columns: repeat(3, minmax(0, 300px));
        justify-content: center;
        gap: 2rem;
        padding: 0;
    }
`;

export const ServiceBottomGrid = styled.div`
    display: grid;
    gap: 1rem;
    padding: 0 1rem;
    margin-top: 1rem;

    @media screen and (min-width: 1024px) {
        margin-top: 2rem;
        grid-template-columns: repeat(2, minmax(0, 300px));
        gap: 2rem;
        justify-content: center;
        padding: 0;
    }
`;

export const ServiceCard = styled.div`
    border-radius: 10px;
    background-color: #FFF;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 4px;
    padding: 1.5rem;
`;

export const ServiceCardContent = styled.div`
    display: flex;
    flex-direction: column;

    & > h1 {
        font-size: 20px;
        margin: 1.5rem 0 8px;
    }

    & > p {
        line-height: 1.8;
        font-size: 18px;
        color: #333;
        opacity: .6;
        max-width: 75%;
    }
`;