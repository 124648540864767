import React from "react";
import img01 from '../../assets/images/why/01.png';
import img02 from '../../assets/images/why/02.png';
import img03 from '../../assets/images/why/03.png';
import img04 from '../../assets/images/why/04.png';
import { Section, SectionHeaderCentered, SectionWrapper } from "../../global";
import { ImgWrapper, WhyCard, WhyContent, WhyGrid } from "./style";

const data = [
    {
        title: "Proven Expertise",
        description: "Extensive experience in leading early stage to publicly listed companies",
        image: img01
    },
    {
        title: "Diverse Skill Set",
        description: "Expertise in strategy, operations, digital transformation, and fundraising",
        image: img02
    },
    {
        title: "Successful Track Record",
        description: "Supported companies from pre IPO to IPO with significant growth",
        image: img03
    },
    {
        title: "Transition Management",
        description: "Facilitating generational shifts to ensure continuity and sustainable growth",
        image: img04
    },
]

export const Why = () => {
    return (
        <Section>
            <SectionWrapper>
                <SectionHeaderCentered data-aos="fade-down">
                    <h1>WHY US</h1>
                    <h2>Building the Ecosystem</h2>
                    <p>Expertise in multiple vertical with proven <br />experience in leading companies</p>
                </SectionHeaderCentered>
                <WhyGrid>
                    {data.map((item, index) => <WhyCard key={index} data-aos="fade-up">
                        <WhyContent>
                            <ImgWrapper>
                                <img src={item.image} alt="why" />
                            </ImgWrapper>
                            <h1>{item.title}</h1>
                            <p>{item.description}</p>
                        </WhyContent>
                    </WhyCard>)}
                </WhyGrid>
            </SectionWrapper>
        </Section>
    )
}