import React from "react";
import logo from '../../assets/images/image 27.png';
import { Section, SectionWrapper } from "../../global";
import { SectionFlex, SectionRight } from "../featured/prohiring/style";
import { BgWrapper } from "../header/style";
import { Contact, SectionLeft } from "./style";

export const Footer = () => {
    return (
        <Section backgroundColor="#222631">
            <BgWrapper />
            <SectionWrapper>
                <SectionFlex>
                    <SectionRight>
                        <img src={logo} alt="logo" />
                        <p style={{ color: "#fff" }}>We drive business growth with innovative strategies and tailored solutions. Let us propel your success to new heights.</p>
                    </SectionRight>
                    <SectionLeft>
                        <h1>Get in Touch</h1>
                        <Contact>
                            <a href="mailto:troy@katalystcap.com" target="_blank" rel="noreferrer">troy@katalystcap.com</a>
                            <a href="mailto:yonky@katalystcap.com" target="_blank" rel="noreferrer">yonky@katalystcap.com</a>
                        </Contact>
                    </SectionLeft>
                </SectionFlex>
            </SectionWrapper>
        </Section>
    )
}