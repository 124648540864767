import { Handshake, HeadCircuit, Rows, SelectionForeground, UsersThree } from "@phosphor-icons/react";
import React from "react";
import { Section, SectionHeaderCentered, SectionWrapper } from "../../global";
import { ServiceBottomGrid, ServiceCard, ServiceCardContent, ServiceGrid } from "./style";

export const Services = () => {
    return (
        <Section backgroundColor="#FAFAFC" id="services">
            <SectionWrapper>
                <SectionHeaderCentered data-aos="fade-down">
                    <h1>WHAT WE DO</h1>
                    <h2>Supercharge your business</h2>
                    <p>Explore tailored products, innovative solutions, and valuable resources designed to accelerate your business growth.</p>
                </SectionHeaderCentered>
                <ServiceGrid>
                    <ServiceCard data-aos="fade-up">
                        <ServiceCardContent>
                            <SelectionForeground color="#146EF5" size={40} />
                            <h1>Pre Screening</h1>
                            <p>Comprehensive understanding of your business</p>
                        </ServiceCardContent>
                    </ServiceCard>
                    <ServiceCard data-aos="fade-up">
                        <ServiceCardContent>
                            <HeadCircuit color="#146EF5" size={40} />
                            <h1>Strategize</h1>
                            <p>In depth analysis to identify your specific needs</p>
                        </ServiceCardContent>
                    </ServiceCard>
                    <ServiceCard data-aos="fade-up">
                        <ServiceCardContent>
                            <Rows color="#146EF5" size={40} />
                            <h1>Introduction</h1>
                            <p>Connecting you with relevant partners</p>
                        </ServiceCardContent>
                    </ServiceCard>
                </ServiceGrid>
                <ServiceBottomGrid>
                    <ServiceCard data-aos="fade-up">
                        <ServiceCardContent>
                            <UsersThree color="#146EF5" size={40} />
                            <h1>Implementation</h1>
                            <p>Collaborating to execute strategies & partnership</p>
                        </ServiceCardContent>
                    </ServiceCard>
                    <ServiceCard data-aos="fade-up">
                        <ServiceCardContent>
                            <Handshake color="#146EF5" size={40} />
                            <h1>Follow Up</h1>
                            <p>Ensuring successful IPO, exit or sustainable growth</p>
                        </ServiceCardContent>
                    </ServiceCard>
                </ServiceBottomGrid>
            </SectionWrapper>
        </Section>
    )
}