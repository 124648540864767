import styled from "styled-components";

export const FundGrid = styled.div`
    display: grid;
    margin-top: 2rem;
    padding: 0 1rem;
    gap: 1rem;

    @media screen and (min-width: 1024px) {
        grid-template-columns: repeat(3, minmax(0, 300px));
        gap: 2rem;
        padding: 0;
        justify-content: center;
    }
`;

export const FundCard = styled.div`
    position: relative;

    & > img {
        object-fit: cover;
        border-radius: 10px;
        width: 100%;
        height: 100%;
    }
`;

export const BlackOverlay = styled.div`
    background-color: #000;
    border-radius: 10px;
    opacity: .5;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
`;

export const FundTitle = styled.div`
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    padding: 1.5rem;

    & > h1 {
        color: #fff;
        font-size: 28px;
        margin-bottom: 1rem;
    }

    & > p {
        color: #fff;
        font-size: 18px;
        line-height: 1.5;
    }
`;