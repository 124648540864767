import styled from "styled-components";
import bg from '../../assets/images/bg.png';

export const HeaderWrapper = styled.div`
    background-color: #222631;
    position: relative;
    z-index: 0;
`;

export const BgWrapper = styled.div`
    background-image: url(${bg});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 25vw 0px;
    opacity: .15;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
`;

export const HeaderContainer = styled.div`
    padding: 7rem 2rem 4rem;
    z-index: 999;
    position: relative;

    & > img {
        width: 100%;
        border-radius: 10px;
    }

    @media screen and (min-width: 1024px) {
        padding: 8rem 2rem 4rem;
    }
`;

export const HeaderTitle = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 2rem;
    margin-bottom: 2rem;

    & > h1 {
        color: #fff;
        font-size: 47px;
    }

    @media screen and (min-width: 1024px) {
        flex-direction: row;

        & > h1 {
            width: 50%;
        }
    }
`;

export const HeaderDesc = styled.div`
    display: flex;
    flex-direction: column;

    & > p {
        color: #fafafa;
        opacity: .6;
        line-height: 1.8;
        font-size: 18px;
    }

    @media screen and (min-width: 1024px) {  
        width: 50%;
    }
`;

export const HeaderButton = styled.a`
    background-color: #064173;
    text-decoration: none;
    padding: 14px 3rem;
    color: #fff;
    border-radius: 10px;
    align-self: flex-start;
    font-weight: 700;
    margin-top: 1rem;
`;