import React from "react";
import styled from "styled-components";
import { Section, SectionWrapper } from "../../global";
import { SectionFlex, SectionRight } from "../featured/prohiring/style";

const Form = styled.form`
    margin-top: 2rem;

    & > input {
        background-color: #e7f0fe;
        border-radius: 10px;
        height: 54px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 2rem;
        border: 0;
        font-size: 16px;
    }

    & > textarea {
        background-color: #e7f0fe;
        border-radius: 10px;
        font-size: 16px;
        padding: 1rem 2rem;
        border: 0;
        width: 100%;
        margin-top: 1.5rem;
        min-height: 250px;
    }

    & > button {
        background-color: #146EF5;
        outline: 0;
        border: 0;
        color: #fff;
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        font-size: 16px;
        margin-top: 8px;
    }

    @media screen and (min-width: 1024px) {
        margin-top: 0;
    }
`;

const FormFlex = styled.div`
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    flex-direction: column;

    & > input {
        background-color: #e7f0fe;
        border-radius: 10px;
        height: 54px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 2rem;
        border: 0;
        font-size: 16px;
    }
    
    @media screen and (min-width: 1024px) {
        flex-direction: row;
    }
`;

export const Contact = () => {
    return (
        <Section>
            <SectionWrapper>
                <SectionFlex>
                    <SectionRight data-aos="fade-down">
                        <h1>CONTACT US</h1>
                        <h2>Let's Get to Work</h2>
                        <p>We’re eager to connect and explore the unique possibilities for your brand and company.</p>
                    </SectionRight>
                    <Form data-aos="fade-down" method="POST" action="https://formspree.io/f/mldrzegq">
                        <FormFlex>
                            <input placeholder="Your Name" name="name" />
                            <input placeholder="Your Email" type="email" name="email" />
                        </FormFlex>
                        <textarea placeholder="Your Message" name="message" />
                        <button>Submit</button>
                    </Form>
                </SectionFlex>
            </SectionWrapper>
        </Section>
    )
}