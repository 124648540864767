import styled from "styled-components";

export const SectionFlex = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    z-index: 2;
    padding: 0 1rem;

    & > p {
        color: #333;
        opacity: .6;
        font-size: 18px;
        line-height: 1.8;
        margin-top: 1rem;
        text-align: left;
    }

    @media screen and (min-width: 1024px) {
        padding: 0;
        flex-direction: row;

        & > p {
            text-align: right;
            max-width: 40%;
        }
    }
`;

export const SectionRight = styled.div`
    margin-bottom: 2rem;
    & > h1 {
        color: #146EF5;
        font-size: 14px;
    }

    & > h2 {
        font-size: 47px;
        color: #333333;
        letter-spacing: -2px;
    }


    & > p {
        color: #333;
        opacity: .6;
        font-size: 16px;
        line-height: 1.8;
        margin-top: 1rem;
    }

    @media screen and (min-width: 1024px) {
        margin: 0;
        
        & > p {
            max-width: 50%;
            font-size: 18px;
        }
    }
`;

export const ProHiringGrid = styled.div`
    display: grid;
    margin-top: 2rem;
    padding: 0 1rem;
    gap: 1rem;

    @media screen and (min-width: 1024px) {
        grid-template-columns: repeat(3, minmax(0 ,1fr));
        gap: 2rem;
        padding: 0;
        justify-content: center;
    }
`;

export const ProHiringCard = styled.div`
    background-color: #F6F6F7;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 2rem;

    & > h1 {
        font-size: 21px;
        margin: 2rem 0 12px;
    }

    & > p {
        color: #333;
        opacity: .7;
        font-size: 18px;
        line-height: 1.5;
    }
`;