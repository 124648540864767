import { Briefcase, ChartLineUp, MoneyWavy, RadioButton } from "@phosphor-icons/react";
import React from "react";
import { Section, SectionHeaderCentered, SectionWrapper } from "../../global";
import { BgLeftWrapper, PortfolioCard, PortfolioGrid } from "./style";

const data = [
    {
        title: "2 IPOs",
        icon: <RadioButton size={30} />
    },
    {
        title: "1 Exit",
        icon: <Briefcase size={30} />
    },
    {
        title: "$300M raised in Debt & Equity",
        icon: <ChartLineUp size={30} />
    },
    {
        title: "$150M Syndicated Loan",
        icon: <MoneyWavy size={30} />
    },
]

export const Portfolio = () => {
    return (
        <Section backgroundColor="#222631" id="portfolio">
            <BgLeftWrapper />
            <SectionWrapper>
                <SectionHeaderCentered data-aos="fade-down">
                    <h1>OUR PORTFOLIO</h1>
                    <h2 style={{ color: "#fff" }}>Track Record</h2>
                    <p style={{ color: "#FAFAFA", opacity: .6 }}>Solid portfolio in advisory and <br /> fundraising experience</p>
                </SectionHeaderCentered>
                <PortfolioGrid>
                    {data.map((item, index) => <PortfolioCard key={index} data-aos="fade-up">
                        {item.icon} <span>{item.title}</span>
                    </PortfolioCard>)}
                </PortfolioGrid>
            </SectionWrapper>
        </Section>
    )
}