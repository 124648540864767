import styled from "styled-components";
import bg from '../../assets/images/bg.png';

export const BgLeftWrapper = styled.div`
    background-image: url(${bg});
    background-repeat: no-repeat;
    background-size: contain;
    /* opacity: .15; */
    position: absolute;
    top: 0;
    left: -200px;
    width: 100%;
    height: 100%;
    z-index: 0;
`;

export const PortfolioGrid = styled.div`
    display: grid;
    margin-top: 2rem;
    gap: 1rem;
    padding: 0 1rem;

    @media screen and (min-width: 1024px) {
        gap: 2rem;
        padding: 0;
        grid-template-columns: repeat(2, minmax(0, 300px));
        justify-content: center;
    }
`;

export const PortfolioCard = styled.div`
    background-color: #000;
    border-radius: 10px;
    display: flex;
    gap: 1rem;
    padding: 1.25rem 1rem 1.25rem 2rem;
    align-items: center;
    color: #fff;

    & > span {
        color: #fff;
    }
`;