import styled from "styled-components";

export const SectionLeft = styled.div`
    & > h1 {
        color: #fff;
        font-size: 38px;
        letter-spacing: -2px;
    }
`;

export const Contact = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 2rem;

    & > a {
        color: #fff;
        text-decoration: none;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
`;