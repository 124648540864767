import React from "react";
import styled from "styled-components";
import logo from '../../assets/images/image 27.png';

const NavbarWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 9999;
    background-color: #12141a90;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
`

export const NavbarContainer = styled.nav`
    padding: 12px 1rem;
    margin: 0 auto;
    max-width: 1024px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > img {
        width: 75px;
    }
`;

export const NavbarMenu = styled.div`
    display: none;
    gap: 14px;

    & > a {
        text-decoration: none;
        color: #fff;
        font-weight: 600;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    @media screen and (min-width: 1024px) {
        display: flex;
    }
`;

export const Navbar = () => {
    return (
        <NavbarWrapper>
            <NavbarContainer>
                <img src={logo} alt="logo" />
                <NavbarMenu>
                    <a href="#services">Our Services</a>
                    <a href="#partners">Our Partners</a>
                    <a href="#portfolio">Portfolio</a>
                    <a href="#featured">Featured Services</a>
                </NavbarMenu>
            </NavbarContainer>
        </NavbarWrapper>
    )
}