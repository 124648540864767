import styled from "styled-components";

export const SyncGrid = styled.div`
    display: grid;
`;

export const SyncThreeGrid = styled(SyncGrid)`
    margin-top: 2rem;
    gap: 1rem;
    padding: 0 1rem;

    @media screen and (min-width: 1024px) {
        grid-template-columns: repeat(3, minmax(0, 300px));
        justify-content: center;
        padding: 0;
    }
`;

export const SyncDoubleGrid = styled(SyncGrid)`
    margin-top: 1rem;
    gap: 1rem;
    padding: 0 1rem;

    @media screen and (min-width: 1024px) {
        grid-template-columns: repeat(2, minmax(0, 300px));
        justify-content: center;
        padding: 0;
    }
`;

export const SyncCard = styled.div`
    background-color: #F6F6F7;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    padding: 12px 1.5rem;
    gap: 1rem;
    align-items: center;

    & > span {
        font-weight: 600;
    }
`;