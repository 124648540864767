import React from "react";
import { default as img01 } from '../../assets/images/partners/10.png';
import comp01 from '../../assets/images/partners/company/01.png';
import comp02 from '../../assets/images/partners/company/02.png';
import comp03 from '../../assets/images/partners/company/03.png';
import comp04 from '../../assets/images/partners/company/04.png';
import comp11 from '../../assets/images/partners/company/11.webp';
import { Section, SectionHeader, SectionWrapper } from "../../global";
import { CompaniesWrapper, PartnerCard, PartnerContent, PartnerFlex, PartnerTitle, Title } from "./style";

const data = [
    {
        name: "Troy Parwata",
        role: "Managing Partner",
        image: img01,
        desc: "25+ Years of strategy, operations and finance. Expertise in operation, finance, and expansion",
        title: [
            "Group CFO Rucika Group", "Former Managing Director MPMX", "Former CFO Tiga Raksa Satria TBK", "Former CFO Mattel", "Former CFO TNT"
        ],
        companies: [
            comp04, comp03, comp02, comp01, comp11
        ]
    },
    // {
    //     name: "Yonky Saputra",
    //     role: "Partner",
    //     image: img02,
    //     desc: "15+ Years of strategy, operations and finance. Brings expertise in strategy and restructuring",
    //     title: [
    //         "Former CFO Molindo Group", "Former VP of Finance UPH", "CFO Indogal", "Former Financial Director Group M", "Former CFO TopIndo"
    //     ],
    //     companies: [
    //         comp08, comp09, comp07, comp10, comp06, comp05,
    //     ]
    // }
]

export const Partner = () => {
    return (
        <Section backgroundColor="#FAFAFC" id="partners">
            <SectionWrapper>
                <SectionHeader data-aos="fade-down">
                    <h1>TEAM MEMBERS</h1>
                    <h2 style={{ textAlign: "left" }}>Meet Our Partners</h2>
                    <p>With a passion for innovation and a dedication to excellence, we bring diverse expertise to every project we undertake.</p>
                </SectionHeader>
                <PartnerFlex>
                    {data.map((item, index) => <PartnerCard key={index}>
                        <img src={item.image} alt={item.name} data-aos="fade-up" />
                        <PartnerContent data-aos="fade-up">
                            <h1>{item.name}</h1>
                            <h2>{item.role}</h2>
                            <p>{item.desc}</p>
                            <PartnerTitle data-aos="fade-up">
                                {item.title.map((title, index) => <Title style={{ flexGrow: title.includes("TNT") || title.includes("TopIndo") ? "0" : "1" }} key={index}>
                                    <span>{title}</span>
                                </Title>)}
                            </PartnerTitle>
                            <CompaniesWrapper data-aos="fade-up">
                                {item.companies.map((img, index) => <img key={index} src={img} alt="item.name" />)}
                            </CompaniesWrapper>
                        </PartnerContent>
                    </PartnerCard>)}
                </PartnerFlex>
            </SectionWrapper>
        </Section>
    )
}