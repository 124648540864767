import { ChartBar, PresentationChart, ProjectorScreenChart, Speedometer, UserFocus } from "@phosphor-icons/react";
import React from "react";
import { Section, SectionHeaderCentered, SectionWrapper } from "../../../global";
import { SyncCard, SyncDoubleGrid, SyncThreeGrid } from "./style";

export const Sync = () => {
    return (
        <Section id="featured">
            <SectionWrapper>
                <SectionHeaderCentered data-aos="fade-down">
                    <h1>FEATURED SERVICES</h1>
                    <h2>Sync Vision</h2>
                    <p>Aligning Our Vision with Yours</p>
                </SectionHeaderCentered>
                <SyncThreeGrid>
                    <SyncCard data-aos="fade-up">
                        <ProjectorScreenChart size={30} />
                        <span>Strategic Partnership</span>
                    </SyncCard>
                    <SyncCard data-aos="fade-up">
                        <Speedometer size={30} />
                        <span>Scalable & Sustainability</span>
                    </SyncCard>
                    <SyncCard data-aos="fade-up">
                        <ChartBar size={30} />
                        <span>Fundraising</span>
                    </SyncCard>
                </SyncThreeGrid>
                <SyncDoubleGrid>
                    <SyncCard data-aos="fade-up">
                        <UserFocus size={30} />
                        <span>Pro Hiring</span>
                    </SyncCard>
                    <SyncCard data-aos="fade-up">
                        <PresentationChart size={30} />
                        <span>Operational Restructure</span>
                    </SyncCard>
                </SyncDoubleGrid>
            </SectionWrapper>
        </Section>
    )
}