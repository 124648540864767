import React from "react";
import header from '../../assets/images/banner.webp';
import { SectionWrapper } from "../../global";
import { BgWrapper, HeaderButton, HeaderContainer, HeaderDesc, HeaderTitle, HeaderWrapper } from "./style";

export const Header = () => {
    return (
        <HeaderWrapper>
            <BgWrapper />
            <SectionWrapper>
                <HeaderContainer>
                    <HeaderTitle data-aos="fade">
                        <h1>Catalyzing Your
                            Business Growth</h1>
                        <HeaderDesc>
                            <p>
                                We drive business growth with innovative strategies and tailored solutions. Let us propel your success to new heights.
                            </p>
                            <HeaderButton href="https://wa.me/+628111997144?text=Hi, I'd like to explore more on your services">
                                <span>Explore Now</span>
                            </HeaderButton>
                        </HeaderDesc>
                    </HeaderTitle>
                    <img src={header} alt="header" data-aos="fade-up" />
                </HeaderContainer>
            </SectionWrapper>
        </HeaderWrapper>
    )
}