import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from 'react';
import './App.css';
import { Contact } from './sections/contact';
import { Fundraising } from './sections/featured/fundraising';
import { ProHiring } from './sections/featured/prohiring';
import { Restructure } from './sections/featured/restrutcture';
import { Sync } from './sections/featured/sync';
import { Footer } from './sections/footer';
import { Header } from './sections/header';
import { Navbar } from "./sections/navbar";
import { Partner } from './sections/partners';
import { Portfolio } from './sections/portfolio';
import { Services } from './sections/services';
import { Why } from './sections/why';

const App = () => {

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  })
  return (
    <>
      <Navbar />
      <Header />
      <Services />
      <Why />
      <Partner />
      <Portfolio />
      <Sync />
      <Fundraising />
      <ProHiring />
      <Restructure />
      <Contact />
      <Footer />
    </>
  );
}

export default App;
