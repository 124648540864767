import styled from "styled-components";

export const PartnerFlex = styled.div`
    display: grid;
    gap: 1rem;
    margin-top: 2rem;
    flex-direction: column;
    padding: 0 1rem;

    @media screen and (min-width: 1024px) {
        display: flex;
        justify-content: center;
        /* grid-template-columns: repeat(1, minmax(0, 1fr)); */
        gap: 2rem;
        flex-direction: row;
        padding: 0;
    }
`;

export const PartnerCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    & > img {
        box-shadow: rgba(0, 0, 0, 0) 0px 0px, rgba(0, 0, 0, 0) 0px 0px, rgb(0, 0, 0) 2px 2px 0px 0px !important;
        border: 1px solid #000;
        border-radius: 20px;
        width: 100%;
    }

    @media screen and (min-width: 1024px) {
        & > img {
            width: 50%;
        }
    }
`;

export const PartnerContent = styled.div`
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > h1 {
        letter-spacing: -2px;
    }

    & > h2 {
        color: #146EF5;
        font-size: 20px;
        margin: 2px 0 10px;
    }

    & > p {
        color: #1E1E24;
        opacity: .7;
        font-size: 16px;
        max-width: 55%;
        text-align: center;
    }
`;

export const PartnerTitle = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 6px;
    margin-top: 1rem;
    flex: 2;

    @media screen and (min-width: 1024px) {
        flex-direction: row;
        max-width: 50%;
    }
`;

export const Title = styled.div`
    box-shadow: rgba(0, 0, 0, 0) 0px 0px, rgba(0, 0, 0, 0) 0px 0px, rgb(0, 0, 0) 1px 1px 0px 0px !important;
    border: 1px solid #000;
    border-radius: 10px;
    padding: 6px 20px;
    flex-shrink: 1;
    flex-grow: 0;
    display: flex;
    justify-content: center;

    & > span {
        font-size: 15px;
        color: #1E1E24;
        opacity: .7;
    }
    
    @media screen and (min-width: 1024px) {
        flex-grow: 1;
    }
`;

export const CompaniesWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    justify-content: center;
    margin-top: 1.5rem;

    & > img {
        object-fit: contain;
        width: 50px;
    }

    @media screen and (min-width: 1024px) {
        & > img {
            width: 70px;
        }
    }
`;